.App {
  margin: 0;
  font-family: "Open Sans, sans-serif";
}

.amplify-button[data-variation="primary"] {
  border-radius: var(--amplify-radii-xl);
}

#map {
  width: 100vw;
  height: 100vh;
}

.mapboxgl-canvas-container {
  width: 100vw;
  height: 100vh;
}
